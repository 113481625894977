.duplicate-code {
    font-weight: bold;
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 1rem;
    margin-bottom: 1rem;
}

.duplicate-list{
    margin-top: 2rem;
    display: flex;
    width: 100%;
    justify-content: start;
    flex-wrap: wrap;
}