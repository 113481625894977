.issues-filter {
    width: 215px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.issues-filter button {
    font-size: 1.1rem;
}

.table-excel [class*="MuiToolbar-gutters"] {
    padding-left: 0 !important;
}

.table-excel div > .MuiToolbar-root {
    background-color: #fff !important;
    display: flex !important;
}

.table-excel div > .MuiToolbar-root div:nth-of-type(1) {

    order: 2 !important;
}

.table-excel div > .MuiToolbar-root div:nth-of-type(2) {
    order: 1 !important;
}

.table-excel div > .MuiToolbar-root div:nth-of-type(3) {
    order: 3 !important;
}

.table-excel div > .MuiToolbar-root div:nth-of-type(4) {
    order: 4 !important;
}