/*
  Common variables:
  - font-size
  - color
  - padding
  - border-color
*/
.intelepeer-table thead th {
  padding: 8px 5px;
  color: #2B2B2B; }

.intelepeer-table tbody td {
  font-size: 0.85rem !important;
  color: #2B2B2B !important;
  padding: 8px 5px !important;
  border-bottom: solid 1px #e4eaec !important;
  /*  & > * {
    font-size: $fontSize !important; // TODO apply font-size to all the children of the td
  }*/ }

.intelepeer-table tbody tr:hover {
  background-color: #f2f4f773 !important; }

@media screen and (max-width: 720px) {
  .intelepeer-table tbody td > * {
    font-size: 0.75rem !important; } }

@media screen and (min-width: 720px) and (max-width: 1024px) {
  .intelepeer-table tbody td > * {
    font-size: 0.80rem !important; } }

@media screen and (min-width: 1025px) and (max-width: 1280px) {
  .intelepeer-table tbody td > * {
    font-size: 0.9rem !important; } }

@media screen and (min-width: 1281px) and (max-width: 1600px) {
  .intelepeer-table tbody td > * {
    font-size: 1rem !important; } }

.intelepeer-width-column-all thead th,
.intelepeer-width-column-all thead td,
.intelepeer-width-column-all tbody th,
.intelepeer-width-column-all tbody td {
  width: 200px !important;
  min-width: 150px !important; }
  .intelepeer-width-column-all thead th:nth-child(8),
  .intelepeer-width-column-all thead td:nth-child(8),
  .intelepeer-width-column-all tbody th:nth-child(8),
  .intelepeer-width-column-all tbody td:nth-child(8) {
    width: 50px !important;
    min-width: 50px !important; }
  .intelepeer-width-column-all thead th:nth-child(9),
  .intelepeer-width-column-all thead td:nth-child(9),
  .intelepeer-width-column-all tbody th:nth-child(9),
  .intelepeer-width-column-all tbody td:nth-child(9) {
    width: 50px !important;
    min-width: 50px !important; }
