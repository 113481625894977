.upload-file{
    padding: 6px;
    border-radius: 4px;
    border: 1px solid rgb(206, 212, 218);
}

.upload-input{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.upload-text{
    display: flex;
    flex-direction: column;
}

.upload-icon {
    display: flex;
}