.extraction-dates{
    list-style: none;
    padding: 1rem;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.extraction-tabs {
    padding: 0 2.5rem;
    display: flex;
    justify-content: center;
}

.extraction-legend {
    padding: 0 3rem;
}

.extraction-dates-red {
    color: #962d00;
}

.extraction-dates-green {
    color: #1c7430;
}

.extraction-item:not(:last-of-type) {
    margin-right: .625rem;
    margin-bottom: 0.625rem;
}

.extraction-enabled::before{
    content: ' ';
    width: 14px;
    height: 14px;
    display: inline-block;
    background-color: #28a745;
    margin-right: .625rem;
}

.extraction-disabled::before{
    content: ' ';
    width: 14px;
    height: 14px;
    display: inline-block;
    background-color: #ffc107;
    margin-right: .625rem;
}