.legend {
    font-size: 1.15rem;
}

.legend-total {
    margin-right: 1rem;
}

.legend-filter {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.legend-item:not(:last-of-type)::after {
    padding: 0.5rem;
    color: #6c757d;
    content: "/";
}

.legend .btn {
    color: #fff;
    background-color: #3e8ef7;
    border-color: #e4eaec;
    box-shadow: none;
    border-radius: 50%;
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

