@font-face {
  font-family: "Montserrat";
  src: url("./font/Montserrat-Bold.woff2") format("woff2"), url("./font/Montserrat-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Montserrat";
  src: url("./font/Montserrat-BlackItalic.woff2") format("woff2"), url("./font/Montserrat-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "Montserrat";
  src: url("./font/Montserrat-ExtraBold.woff2") format("woff2"), url("./font/Montserrat-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Montserrat";
  src: url("./font/Montserrat-BoldItalic.woff2") format("woff2"), url("./font/Montserrat-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "Montserrat";
  src: url("./font/Montserrat-Black.woff2") format("woff2"), url("./font/Montserrat-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Montserrat";
  src: url("./font/Montserrat-Medium.woff2") format("woff2"), url("./font/Montserrat-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Montserrat";
  src: url("./font/Montserrat-ExtraBoldItalic.woff2") format("woff2"), url("./font/Montserrat-ExtraBoldItalic.woff") format("woff");
  font-weight: 800;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "Montserrat";
  src: url("./font/Montserrat-Italic.woff2") format("woff2"), url("./font/Montserrat-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "Montserrat";
  src: url("./font/Montserrat-ExtraLight.woff2") format("woff2"), url("./font/Montserrat-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Montserrat";
  src: url("./font/Montserrat-LightItalic.woff2") format("woff2"), url("./font/Montserrat-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "Montserrat";
  src: url("./font/Montserrat-Light.woff2") format("woff2"), url("./font/Montserrat-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Montserrat";
  src: url("./font/Montserrat-ExtraLightItalic.woff2") format("woff2"), url("./font/Montserrat-ExtraLightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "Montserrat";
  src: url("./font/Montserrat-Regular.woff2") format("woff2"), url("./font/Montserrat-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Montserrat";
  src: url("./font/Montserrat-SemiBold.woff2") format("woff2"), url("./font/Montserrat-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Montserrat";
  src: url("./font/Montserrat-ThinItalic.woff2") format("woff2"), url("./font/Montserrat-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "Montserrat";
  src: url("./font/Montserrat-SemiBoldItalic.woff2") format("woff2"), url("./font/Montserrat-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "Montserrat";
  src: url("./font/Montserrat-MediumItalic.woff2") format("woff2"), url("./font/Montserrat-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "Montserrat";
  src: url("./font/Montserrat-Thin.woff2") format("woff2"), url("./font/Montserrat-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap; }

* {
  box-sizing: border-box; }

body {
  background: #f1f4f5; }

strong {
  font-weight: 500; }

a,
a:active,
a:visited,
a:link,
a:hover {
  text-decoration: none; }

.vertical {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }

button {
  border: none !important; }

.hidden {
  display: none; }

.w100 {
  width: 100%; }

.shadown {
  -webkit-box-shadow: 0 0 14px -6px rgba(51, 51, 51, 0.89);
  -moz-box-shadow: 0 0 14px -6px rgba(51, 51, 51, 0.89);
  box-shadow: 0 0 14px -6px rgba(51, 51, 51, 0.89); }

.radius10 {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px; }

.radius5 {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px; }

.floatl {
  float: left; }

.floatr {
  float: right; }

.clearb {
  clear: both; }

.clearl {
  clear: left; }

.clearr {
  clear: right; }

.bg_white {
  background-color: #fff; }

.text-blue {
  color: #0080c0 !important; }

.text-lightblue {
  color: #0af0f7 !important; }

.text-purple {
  color: #7900ff !important; }

.text-white {
  color: #fff !important; }

.bg_orange {
  background-color: #f18a00; }

.text-orange {
  color: #f18a00 !important; }

.bg_gray {
  background-color: #6f7072; }

.text-gray {
  color: #6f7072 !important; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-bold,
.bold {
  font-weight: bold; }

.separe {
  width: 100%;
  padding: 2px; }

.mh50 {
  min-height: 50px; }

.mh60 {
  min-height: 60px; }

.m5 {
  margin: 5px; }

.mb5 {
  margin-bottom: 5px; }

.mt10 {
  margin-top: 10px; }

.m10 {
  margin: 10px; }

.m15 {
  margin: 15px; }

.m20 {
  margin: 20px; }

.m25 {
  margin: 25px; }

.m30 {
  margin: 30px; }

.p3 {
  padding: 3px; }

.p4 {
  padding: 4px; }

.pl10 {
  padding-left: 10px; }

.p5 {
  padding: 5px; }

.p10 {
  padding: 10px; }

.p15 {
  padding: 15px; }

.p20 {
  padding: 20px; }

.p25 {
  padding: 25px; }

.p30 {
  padding: 30px; }

.pt5 {
  padding: 5px 0 0 0; }

.pt7 {
  padding-top: 7px; }

.pl5 {
  padding-left: 5px; }

.pr5 {
  padding-right: 5px; }

.plr5 {
  padding-left: 5px;
  padding-right: 5px; }

.plr10 {
  padding-left: 10px;
  padding-right: 10px; }

.pt10 {
  padding-top: 10px; }

.pt15 {
  padding-top: 15px; }

.pb10 {
  padding-bottom: 10px; }

.pt20 {
  padding-top: 20px; }

.pb20 {
  padding-bottom: 20px; }

.mb20 {
  margin-bottom: 20px; }

.pt30 {
  padding-top: 30px; }

.mt20 {
  margin: 20px 0 0 0; }

.mt30 {
  margin-top: 30px; }

.pb30 {
  padding: 0 0 30px 0; }

.margen {
  padding: 5px; }

.pointer {
  cursor: pointer; }

.highcharts-credits {
  display: none !important; }

.twoCols {
  display: grid;
  display: -ms-grid;
  -ms-grid-columns: 1fr 30%;
  grid-template-columns: 1fr 30%; }

.twoCols .title {
  font-size: 1.5rem; }

.boxWhite {
  background-color: #fff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  padding: 1rem;
  -webkit-box-shadow: 2px 7px 12px -5px rgba(104, 104, 104, 0.55);
  -moz-box-shadow: 2px 7px 12px -5px rgba(104, 104, 104, 0.55);
  box-shadow: 2px 7px 12px -5px rgba(104, 104, 104, 0.55); }

.logo {
  width: 100%;
  text-align: center; }

.logo img {
  max-width: 175px;
  width: 100%;
  margin: 0 auto; }

.margin_logo {
  margin: 1rem 0; }

.login_content {
  width: 100%;
  margin: 0 auto; }

.login_content .login_box {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: #fff; }

.login_content .login_box .login_form {
  width: 390px;
  background: #fff; }

.login_content .login_box .login_form .login_form_box {
  width: 100%;
  margin-top: 1.6rem; }

.login_content .login_box .login_form .login_form_box form .box-input {
  position: relative;
  width: 100%;
  max-width: 280px;
  margin: 1rem auto 0 auto; }

.login_content .login_box .login_form .login_form_box form .box-input label {
  font-weight: 600;
  color: #6f7072; }

.login_content
.login_box
.login_form
.login_form_box
form
.box-input
input.item-control {
  width: 100%;
  font-size: 1rem;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: solid 1px #ccc;
  padding: 10px; }

.login_content .login_box .login_form .login_form_box form .box-input .errors {
  text-align: right;
  font-size: 0.75rem;
  color: #f00; }

.login_content .login_box .login_form .login_form_box form .box-btn {
  width: 250px;
  margin: 2.5rem auto 1rem auto; }

.login_content .login_box .login_form .login_form_box form .box-btn .btn {
  background-color: #0080c0;
  padding: 14px;
  font-size: 1rem;
  color: #fff;
  width: 100%;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  font-size: 1.1rem;
  cursor: pointer; }

.header_box {
  width: 100%;
  background-color: #2b3a4a;
  min-height: 75px;
  padding-bottom: 3rem;
  margin-bottom: 3.8rem; }

.header_box .header_logo {
  width: 100%;
  padding: 0 30px 0 30px;
  background-color: #fff;
  margin: 0; }

.header_box .header_logo .container {
  position: relative; }

.header_box .header_logo .container .row .iconHeader {
  color: #0080c0;
  font-size: 1.3rem;
  cursor: pointer; }

.header_box .header_logo .container .header_menu {
  -webkit-box-shadow: 2px 7px 12px -5px rgba(104, 104, 104, 0.55);
  -moz-box-shadow: 2px 7px 12px -5px rgba(104, 104, 104, 0.55);
  box-shadow: 2px 7px 12px -5px rgba(104, 104, 104, 0.55);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  position: absolute;
  width: 100%;
  background-color: #fff;
  min-height: 60px;
  top: 80px; }

.graph {
  display: grid;
  display: -ms-grid;
  -ms-grid-columns: 30% 1fr 20%;
  grid-template-columns: 30% 1fr 20%; }

.graph .box {
  margin: 0.5rem 0.65rem;
  background-color: #fff;
  -webkit-box-shadow: 0 0 14px -6px rgba(51, 51, 51, 0.89);
  -moz-box-shadow: 0 0 14px -6px rgba(51, 51, 51, 0.89);
  box-shadow: 0 0 14px -6px rgba(51, 51, 51, 0.89);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  padding: 1rem; }

.graph .box .home_title_box {
  padding: 0 5px 1rem 5px;
  font-weight: bold;
  color: #415364;
  text-align: center;
  border-bottom: 1px solid #cecece;
  margin-bottom: 1.5rem; }

.graph .box .graphBox .graphContent {
  text-align: center; }

.dqaDetails {
  font-size: 0.85rem; }

.dqaDetails .grid3 {
  display: grid;
  display: -ms-grid;
  -ms-grid-columns: 28% 1fr 1fr;
  grid-template-columns: 28% 1fr 1fr;
  border-bottom: solid 1px #c6c6c6; }

.dqaDetails .grid3 .col {
  margin: 0.4rem 0.65rem 0.4rem 0.2rem; }

.dqaDetails .grid3 .col:nth-child(1) {
  text-align: left !important;
  font-weight: bold; }

.dqaDetails .grid3 .col:nth-child(1):after {
  content: ":"; }

.dqaDetails .grid3:last-child {
  border-bottom: none; }

.dqaheader {
  display: grid;
  display: -ms-grid;
  -ms-grid-columns: 40% 1fr;
  grid-template-columns: 40% 1fr; }

.dqaheader .title {
  font-size: 1.5rem; }

.dqaheader .dqaMissingCode {
  display: grid;
  display: -ms-grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  color: #444;
  margin-left: 2rem;
  max-width: 700px; }

.dqaheader .dqaMissingCode .item {
  cursor: pointer;
  background-color: #29bbe3;
  margin-top: 0.2rem;
  border-radius: 5px;
  padding: 5px;
  font-size: 1rem;
  position: relative;
  text-transform: uppercase;
  margin: 0 0 0 0.4rem; }

.dqaheader .dqaMissingCode .item .name {
  padding-top: 1.2rem;
  padding-bottom: 3px;
  margin-bottom: 5px;
  border-bottom: solid 2.5px #99e5f8;
  margin: 0;
  font-size: 0.75rem;
  color: #fff; }

.dqaheader .dqaMissingCode .item .value {
  position: absolute;
  top: -10px;
  right: 10px;
  width: 30px;
  height: 30px;
  border: solid 1px #ffecb2;
  background-color: #ffc107;
  border-radius: 50%;
  color: #fff;
  font-weight: bold;
  text-align: center;
  padding-top: 5px;
  font-size: 0.75rem; }

.twoColums {
  display: grid;
  display: -ms-grid;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr; }

.threeColums {
  display: grid;
  display: -ms-grid;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr; }

.template__body {
  min-height: 400px; }

.itemBookmark {
  padding: 10px;
  position: relative;
  border-radius: 4px;
  margin: 2px; }

.itemBookmark .name {
  font-size: 0.75rem;
  padding-right: 30px; }

.itemBookmark .option {
  position: absolute;
  right: 10px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  top: 8px;
  font-size: 1.3rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 2px; }

.boxNoActive {
  color: #3b3838;
  background-color: #e9eff5;
  border: solid 1px #e6ebef; }

.boxNoActive .option {
  background: #f0ffff;
  border: solid 1px #fff;
  color: #5f9ea0; }

.boxActive {
  color: #3b3838;
  background: #e5f7ff;
  border: solid 1px #bee6f7; }

.boxActive .option {
  background: #fff;
  border: solid 1px #ceecf9;
  color: #ff9800; }

.acordeon,
.accordion {
  width: 100%;
  margin: auto; }

.acordeon .acordeon__contenedor,
.accordion .acordeon__contenedor {
  color: #444;
  margin: 0;
  border-radius: 8px;
  border: 1px solid #e0dbdb; }

.acordeon .acordeon__contenedor:nth-child(1),
.accordion .acordeon__contenedor:nth-child(1) {
  margin-top: 0; }

.acordeon .acordeon__titulo,
.accordion .acordeon__titulo {
  color: #18577b;
  background: #f7f7f7;
  line-height: 2.8;
  padding: 0;
  font-size: 1em;
  margin: 0;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  display: grid;
  display: -ms-grid;
  -ms-grid-columns: 1fr 22% 22%;
  grid-template-columns: 1fr 22% 22%; }

.acordeon .acordeon__titulo .btn-link,
.accordion .acordeon__titulo .btn-link {
  color: #18577b;
  font-size: 1rem; }

.acordeon .acordeon__titulo .btn-addSubCat,
.accordion .acordeon__titulo .btn-addSubCat {
  margin: 0 15px 5px 0; }

.acordeon__contenido {
  display: none;
  margin: 0; }

.subsectionTitle {
  font-size: 1rem;
  font-weight: bold;
  padding: 0.5rem 0;
  display: grid;
  display: -ms-grid;
  -ms-grid-columns: 1fr 25%;
  grid-template-columns: 1fr 25%; }

.subsectionTitle .btn-addBookmarks {
  margin: 2px; }

.itemlistAccordeon {
  background: rgba(255, 255, 255, 0.5);
  padding: 0 0 0.5rem 0;
  display: grid;
  display: -ms-grid;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2px; }

.btnList {
  padding: 1rem 0 0 0; }

.optionCategories {
  background-color: #f7f7f7;
  border: solid 1px #ebecec !important;
  text-align: center;
  font-size: 0.84rem;
  color: #50585f; }

.optionCategories .smIcon {
  color: #607d8b; }

.optionCategories:hover {
  background-color: #def3ff;
  border: 1px solid #c8e2f1 !important;
  color: #0f5994; }

.optionCategories:hover .smIcon {
  color: #3b98e2; }

.smIcon {
  font-size: 0.85rem !important;
  margin-top: -2px; }

.lbl_btnList {
  padding: 0 0 4px 5px; }

.btnDownloadTemplate {
  margin: 0 1rem; }

.itmesColOptions .nav-item {
  border-bottom: solid 1px #e8eef3;
  border-right: none;
  border-left: none;
  padding: 5px 0; }

.itmesColOptions .nav-item a.nav-link {
  font-size: 0.9rem !important;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0; }

.itmesColOptions .nav-item a.active {
  background: #e5f7ff !important;
  color: #3b3838;
  border: solid 1px #bee6f7;
  font-size: 0.92rem !important;
  margin: 5px 0;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  line-height: 1.3rem; }

.itmesColOptions .nav-item:last-child {
  border: none; }

.ivmissingCode .formItems h2 {
  font-size: 1.2rem;
  border-bottom: solid 1px #ccc;
  padding: 0 1rem 1rem 0;
  margin: 1rem; }

.ivmissingCode .formMissingCode {
  margin: 1.7rem 1rem 1rem; }

.ivmissingCode .formMissingCode h4 {
  font-size: 1rem;
  margin: 0.3rem 0 0 0; }

.ivmissingCode .formMissingCode .boxFormItems {
  border-bottom: solid 1px rgba(222, 223, 226, 0.361);
  margin: 1rem 0 1rem 0; }

.ivmissingCode .formMissingCode .boxFormItems .col {
  padding: 0; }

.ivmissingCode .formMissingCode .boxFormItems .col:nth-child(1) {
  padding: 0 5px 0 0; }

.ivmissingCode .formMissingCode .boxFormItems .col:nth-child(2) {
  padding: 0 0 0 5px; }

.ivmissingCode
.formMissingCode
.boxFormItems
.form-group
.input-group
.input-group-prepend {
  font-size: 0.85rem; }

.ivmissingCode
.formMissingCode
.boxFormItems
.form-group
.input-group
.input-group-prepend
span {
  font-size: 0.85rem; }

.ivmissingCode .formMissingCode .boxFormItems .form-group .form-label {
  font-size: 0.85rem !important;
  font-weight: 500; }

.ivmissingCode .formMissingCode .boxFormItems .form-group .form-control {
  font-size: 0.85rem !important; }

.ivmissingCode .formMissingCode .boxFormItems .form-group .input-group-prepend,
.ivmissingCode .formMissingCode .boxFormItems .form-group .input-group-text {
  font-size: 0.85rem; }

.btnForm {
  max-width: 150px !important;
  margin: 0 auto; }

.inforResumen .title {
  font-size: 1.3rem;
  padding: 0.5rem 0 0.3rem 0;
  font-weight: bold; }

.inforResumen .items {
  display: grid;
  display: -ms-grid;
  -ms-grid-columns: 1fr 20%;
  grid-template-columns: 1fr 20%;
  grid-gap: 2px;
  margin-bottom: 1rem;
  border-bottom: solid 1px rgba(222, 223, 226, 0.361);
  padding-bottom: 1rem; }

.inforResumen .items .intemInfo {
  display: grid;
  display: -ms-grid;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2px; }

.inforResumen .items .intemInfo .item {
  margin: 0 5px;
  text-align: center; }

.inforResumen .items .intemInfo .item .title {
  font-size: 0.85rem;
  font-weight: bold; }

.inforResumen .items .intemInfo .item .itemValue {
  font-size: 0.85rem;
  font-weight: normal; }

.inforResumen .items .intemInfo .item:nth-child(2) {
  padding: 0 10px;
  border-left: solid 1px rgba(222, 223, 226, 0.361);
  border-right: solid 1px rgba(222, 223, 226, 0.361); }

.inforResumen .items .intemInfo .item:nth-child(3) {
  padding: 0 10px;
  border-right: solid 1px rgba(222, 223, 226, 0.361); }

.bodyInfoResumen {
  padding: 0 1rem; }

.bodyInfoResumen .item {
  padding: 0.5rem 0; }

.bodyInfoResumen .item .title {
  font-size: 1rem;
  font-weight: bold; }

.bodyInfoResumen .item .title span {
  font-weight: normal;
  margin-left: 0.3rem; }

.bodyInfoResumen .item:last-child {
  border-bottom: none; }

.react-datepicker-wrapper {
  flex: auto; }

.tooltip-inner {
  background-color: #0080c0 !important;
  color: white !important; }

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #0080c0 !important; }

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #0080c0 !important; }

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #0080c0 !important; }

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #0080c0 !important; }

.text-primary {
  color: #0080c0 !important; }

.bg-primary {
  background-color: #0080c0 !important; }

.btn-outline-action {
  color: #0080c0 !important;
  transition: 0.5; }
  .btn-outline-action:hover {
    color: white !important;
    background-color: #0080c0 !important; }

.btn-action {
  background-color: #0080c0 !important;
  transition: 0.5; }
  .btn-action:hover {
    background-color: #006fa7 !important; }

.MuiButton-textPrimary,
.MuiIconButton-colorPrimary,
.MuiSvgIcon-colorPrimary,
.MuiIcon-colorPrimary {
  color: #0080c0 !important; }

.accordion .card-header {
  cursor: pointer;
  padding: 0; }

.MuiTableCell-root .badge {
  display: inline-block;
  font-weight: 100;
  margin: 0 auto;
  text-align: center;
  padding: 4px;
  width: 100%; }

.column-highlighted.badge-success {
  background-color: #2a9d8e; }

.column-highlighted.badge-warning {
  background-color: #e2b855;
  color: black; }

.column-highlighted.badge-danger {
  background-color: #e66f51; }

.MTableHeader-header-13,
.MuiTableCell-head {
  z-index: inherit !important; }

.bookmarks-form .title {
  font-size: 1.2rem; }

.bookmarks-form .badge {
  font-weight: 100;
  font-size: 0.9rem;
  padding: 7.5px;
  margin-bottom: 5px;
  margin-right: 5px; }
  .bookmarks-form .badge-primary {
    background-color: #0080c0a9; }
  .bookmarks-form .badge-secondary {
    background-color: #dedede;
    color: black; }

.bookmarks-form .bookmark-avatar {
  width: 30px;
  height: 30px;
  line-height: 33px;
  border-radius: 30px; }

.bookmarks-form table {
  width: 100%; }
  .bookmarks-form table tr:first-child td {
    padding-top: 0; }
  .bookmarks-form table td {
    padding: 10px 7.5px; }
    .bookmarks-form table td:first-child, .bookmarks-form table td:last-child {
      padding: 0; }
    .bookmarks-form table td:nth-child(2) {
      width: 30px; }
    .bookmarks-form table td.actions {
      text-align: right !important; }
  .bookmarks-form table tr:not(:last-child) td {
    border-bottom: solid 1px #dedede; }

.loginv2 {
  padding: 150px 2rem 180px; }
  .loginv2 .form-control {
    box-sizing: border-box;
    height: 2.573rem;
    font-weight: 300;
    box-shadow: none;
    transition: box-shadow 0.25s linear, border 0.25s linear, color 0.25s linear, background-color 0.25s linear;
    -webkit-appearance: none;
    margin-bottom: 1.429rem;
    color: #76838f;
    background-color: #fff !important;
    background-clip: padding-box;
    border: 1px solid #e4eaec !important; }

.page-login-v2 {
  height: 100%;
  overflow-x: hidden; }
  .page-login-v2 .social a {
    border-radius: 100%;
    width: 35px;
    height: 35px;
    text-align: center;
    padding: 0;
    line-height: 35px; }

.page-login-v2:before {
  background-image: url("./assets/images/login.jpg"); }

.page-login-v2 .page-brand-info {
  margin: 350px 100px 0 90px; }

.page-login-v2 .page-brand-info .brand-img {
  max-width: 400px;
  width: auto;
  vertical-align: middle; }

.page-login-v2 .page-brand-info .brand-text {
  display: inline-block;
  vertical-align: middle;
  margin: 11px 0 11px 20px; }

.page-login-v2 .page-brand-info p {
  opacity: 0.6;
  max-width: 650px; }

.page-login-v2 .page-login-main {
  position: absolute;
  right: 0;
  top: 0;
  height: auto;
  min-height: 100%;
  padding: 150px 60px 180px;
  color: #76838f;
  background: #fff; }

.page-login-v2 .page-login-main .brand {
  margin-bottom: 60px; }

.page-login-v2 .page-login-main .brand-img {
  vertical-align: middle; }

.page-login-v2 .page-login-main .brand-text {
  color: #37474f !important;
  display: inline-block;
  vertical-align: middle;
  margin: 11px 0 11px 20px;
  color: #3e8ef7; }

.page-login-v2 form {
  width: 350px;
  margin: 45px 0 20px; }

.page-login-v2 form > button {
  margin-top: 15px; }

.page-login-v2 form a {
  margin-left: 20px; }

.page-login-v2 footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 50px 60px;
  text-align: center; }

.page-login-v2 .social .icon,
.page-login-v2 .social .icon:hover,
.page-login-v2 .social .icon:active {
  color: #fff; }

@media (max-width: 1100px) {
  .page-login-v2 .page-brand-info .brand-img {
    width: 300px; } }

@media (max-width: 860px) {
  .page-login-v2 .page-brand-info .brand-img {
    width: 200px; } }

@media (min-width: 992px) {
  .page-login-v2 .page-content {
    padding-right: 500px; } }

@media (max-width: 991.98px) {
  .page-login-v2 .page-login-main {
    padding-top: 60px; } }

@media (min-width: 768px) and (max-width: 991.98px) {
  .page-login-v2 .page-login-main {
    padding-top: 80px; }
  .page-login-v2 .page-brand-info {
    margin: 160px 0 0 35px; }
  .page-login-v2 .page-brand-info > p {
    opacity: 0;
    color: transparent; } }

@media (max-width: 767.98px) {
  .page-login-v2 .page-login-main {
    padding-top: 60px;
    width: 100%; }
  .page-login-v2 form {
    width: auto; } }

@media (max-width: 479.98px) {
  .page-login-v2 .page-brand-info {
    margin: 220px 0 0; }
  .page-login-v2 .page-login-main {
    padding: 50px 30px 180px; }
  .page-login-v2 form {
    width: auto; }
  .page-login-v2 footer {
    margin: 50px 30px; } }

.layout-full #root {
  height: 100vh; }

.layout-full .page {
  height: 100%;
  padding: 0;
  margin: 0 !important;
  background-color: transparent; }

.missing-table thead th:nth-child(1),
.missing-table thead td:nth-child(1),
.missing-table tbody th:nth-child(1),
.missing-table tbody td:nth-child(1) {
  width: 20px !important; }

.missing-table thead th:nth-child(2),
.missing-table thead td:nth-child(2),
.missing-table tbody th:nth-child(2),
.missing-table tbody td:nth-child(2) {
  width: calc(40%-60px) !important; }

.missing-table thead th:nth-child(3),
.missing-table thead td:nth-child(3),
.missing-table tbody th:nth-child(3),
.missing-table tbody td:nth-child(3) {
  width: 12% !important; }

.missing-table thead th:nth-child(4),
.missing-table thead td:nth-child(4),
.missing-table tbody th:nth-child(4),
.missing-table tbody td:nth-child(4) {
  width: 12% !important; }

.missing-table thead th:nth-child(5),
.missing-table thead td:nth-child(5),
.missing-table tbody th:nth-child(5),
.missing-table tbody td:nth-child(5) {
  width: 12% !important; }

.missing-table thead th:nth-child(6),
.missing-table thead td:nth-child(6),
.missing-table tbody th:nth-child(6),
.missing-table tbody td:nth-child(6) {
  width: 12% !important; }

.missing-table thead th:nth-last-child(),
.missing-table thead td:nth-last-child(),
.missing-table tbody th:nth-last-child(),
.missing-table tbody td:nth-last-child() {
  width: 12% !important; }
  .missing-table thead th:nth-last-child() div,
  .missing-table thead td:nth-last-child() div,
  .missing-table tbody th:nth-last-child() div,
  .missing-table tbody td:nth-last-child() div {
    justify-content: center;
    width: 100%; }

.page-dark.layout-full::after {
  background-color: initial !important; }

.tooltip,
.MuiTooltip-popper {
  z-index: 99999999 !important; }

.swal2-container {
  z-index: 99999999 !important; }

.popover-header {
  background-color: white; }

.react-datepicker-wrapper {
  width: 70%; }
