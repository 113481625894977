/*
  Common variables:
  - font-size
  - color
  - padding
  - border-color
*/
.all-transactions-table thead th {
  padding: 8px 5px;
  color: #2B2B2B; }

.all-transactions-table tbody td {
  font-size: 0.85rem !important;
  color: #2B2B2B !important;
  padding: 8px 5px !important;
  border-bottom: solid 1px #e4eaec !important;
  /*  & > * {
    font-size: $fontSize !important; // TODO apply font-size to all the children of the td
  }*/ }

.all-transactions-table tbody tr:hover {
  background-color: #f2f4f773 !important; }

@media screen and (max-width: 720px) {
  .all-transactions-table tbody td > * {
    font-size: 0.75rem !important; } }

@media screen and (min-width: 720px) and (max-width: 1024px) {
  .all-transactions-table tbody td > * {
    font-size: 0.80rem !important; } }

@media screen and (min-width: 1025px) and (max-width: 1280px) {
  .all-transactions-table tbody td > * {
    font-size: 0.9rem !important; } }

@media screen and (min-width: 1281px) and (max-width: 1600px) {
  .all-transactions-table tbody td > * {
    font-size: 1rem !important; } }

.all-transactions-table thead th:last-child, .all-transactions-table thead td:last-child,
.all-transactions-table tbody th:last-child,
.all-transactions-table tbody td:last-child {
  text-align: center; }

.all-transactions-table thead th:nth-last-child(-n + 3), .all-transactions-table thead td:nth-last-child(-n + 3),
.all-transactions-table tbody th:nth-last-child(-n + 3),
.all-transactions-table tbody td:nth-last-child(-n + 3) {
  min-width: 2% !important; }

.width-column-all thead th:nth-child(1),
.width-column-all thead td:nth-child(1),
.width-column-all tbody th:nth-child(1),
.width-column-all tbody td:nth-child(1) {
  width: 1% !important; }

.width-column-all thead th:nth-child(2),
.width-column-all thead td:nth-child(2),
.width-column-all tbody th:nth-child(2),
.width-column-all tbody td:nth-child(2) {
  width: 7% !important; }

.width-column-all thead th:nth-child(3),
.width-column-all thead td:nth-child(3),
.width-column-all tbody th:nth-child(3),
.width-column-all tbody td:nth-child(3) {
  width: 12% !important; }

.width-column-all thead th:nth-child(4),
.width-column-all thead td:nth-child(4),
.width-column-all tbody th:nth-child(4),
.width-column-all tbody td:nth-child(4) {
  width: 10% !important; }

.width-column-all thead th:nth-child(5),
.width-column-all thead td:nth-child(5),
.width-column-all tbody th:nth-child(5),
.width-column-all tbody td:nth-child(5) {
  width: 2% !important; }

.width-column-all thead th:nth-child(6),
.width-column-all thead td:nth-child(6),
.width-column-all tbody th:nth-child(6),
.width-column-all tbody td:nth-child(6) {
  width: 15% !important; }

.width-column-all thead th:nth-child(7),
.width-column-all thead td:nth-child(7),
.width-column-all tbody th:nth-child(7),
.width-column-all tbody td:nth-child(7) {
  width: 7% !important; }

.width-column-all thead th:nth-child(8),
.width-column-all thead td:nth-child(8),
.width-column-all tbody th:nth-child(8),
.width-column-all tbody td:nth-child(8) {
  width: 12% !important; }

.width-column-all thead th:nth-child(9),
.width-column-all thead td:nth-child(9),
.width-column-all tbody th:nth-child(9),
.width-column-all tbody td:nth-child(9) {
  width: 12% !important; }

.width-column-8 thead th:nth-child(n+2):nth-child(-n+8), .width-column-8 thead td:nth-child(n+2):nth-child(-n+8), .width-column-8 tbody th:nth-child(n+2):nth-child(-n+8), .width-column-8 tbody td:nth-child(n+2):nth-child(-n+8) {
  width: 9.1% !important; }

.width-column-7 thead th:nth-child(n+2):nth-child(-n+7), .width-column-7 thead td:nth-child(n+2):nth-child(-n+7), .width-column-7 tbody th:nth-child(n+2):nth-child(-n+7), .width-column-7 tbody td:nth-child(n+2):nth-child(-n+7) {
  width: 10.4% !important; }

.width-column-6 thead th:nth-child(n+2):nth-child(-n+6), .width-column-6 thead td:nth-child(n+2):nth-child(-n+6), .width-column-6 tbody th:nth-child(n+2):nth-child(-n+6), .width-column-6 tbody td:nth-child(n+2):nth-child(-n+6) {
  width: 10.16% !important; }

.width-column-6 thead th:nth-child(8), .width-column-6 thead td:nth-child(8), .width-column-6 tbody th:nth-child(8), .width-column-6 tbody td:nth-child(8) {
  min-width: 12% !important; }

.width-column-5 thead th:nth-child(n+2):nth-child(-n+6), .width-column-5 thead td:nth-child(n+2):nth-child(-n+6), .width-column-5 tbody th:nth-child(n+2):nth-child(-n+6), .width-column-5 tbody td:nth-child(n+2):nth-child(-n+6) {
  width: 12.2% !important; }

.width-column-5 thead th:nth-child(7), .width-column-5 thead td:nth-child(7), .width-column-5 tbody th:nth-child(7), .width-column-5 tbody td:nth-child(7) {
  min-width: 12% !important; }

.width-column-4 thead th:nth-child(n+2):nth-child(-n+5), .width-column-4 thead td:nth-child(n+2):nth-child(-n+5), .width-column-4 tbody th:nth-child(n+2):nth-child(-n+5), .width-column-4 tbody td:nth-child(n+2):nth-child(-n+5) {
  width: 15.25% !important; }

.width-column-4 thead th:nth-child(6), .width-column-4 thead td:nth-child(6), .width-column-4 tbody th:nth-child(6), .width-column-4 tbody td:nth-child(6) {
  min-width: 12% !important; }

.width-column-3 thead th:nth-child(n+2):nth-child(-n+4), .width-column-3 thead td:nth-child(n+2):nth-child(-n+4), .width-column-3 tbody th:nth-child(n+2):nth-child(-n+4), .width-column-3 tbody td:nth-child(n+2):nth-child(-n+4) {
  width: 20.3% !important; }

.width-column-3 thead th:nth-child(5), .width-column-3 thead td:nth-child(5), .width-column-3 tbody th:nth-child(5), .width-column-3 tbody td:nth-child(5) {
  min-width: 12% !important; }

.width-column-2 thead th:nth-child(n+2):nth-child(-n+3), .width-column-2 thead td:nth-child(n+2):nth-child(-n+3), .width-column-2 tbody th:nth-child(n+2):nth-child(-n+3), .width-column-2 tbody td:nth-child(n+2):nth-child(-n+3) {
  width: 30.5% !important; }

.width-column-2 thead th:nth-child(4), .width-column-2 thead td:nth-child(4), .width-column-2 tbody th:nth-child(4), .width-column-2 tbody td:nth-child(4) {
  min-width: 12% !important; }

.width-column-1 thead th:nth-child(n+2):nth-child(-n+2), .width-column-1 thead td:nth-child(n+2):nth-child(-n+2), .width-column-1 tbody th:nth-child(n+2):nth-child(-n+2), .width-column-1 tbody td:nth-child(n+2):nth-child(-n+2) {
  width: 61% !important; }

.width-column-1 thead th:nth-child(3), .width-column-1 thead td:nth-child(3), .width-column-1 tbody th:nth-child(3), .width-column-1 tbody td:nth-child(3) {
  min-width: 12% !important; }
