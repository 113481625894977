.credential {
    border: solid 1px #dedede;
    padding: 0.6rem;
    border-radius: 5px;
    min-width: 200px;
    margin: 0 .625rem .625rem;
}

.credential-form .panel-body{
    padding: 30px;
}

.credential-form label {
    color: #76838f;
    margin-bottom: 7.5px;
    font-weight: 500;
    padding: 0;
    font-style: normal;
}