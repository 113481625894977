
.skeleton {
    width: 100%;
    height: 20px;
    background-color: #f0f0f0;
    border-radius: 4px;
    animation: skeleton-loading 1s ease-in-out infinite alternate;
}

@keyframes skeleton-loading {
    0% {
        opacity: 0.8;
    }
    100% {
        opacity: 1;
    }
}

#phone > iframe {
    border: none !important;
    padding: 2rem;
}

.icon {
    background-color: transparent;
    cursor: pointer;;
}

.container-iframe{
    position: fixed;
    top: 120px;
    right: 60px;
    background-color: #fcfcfc;
    z-index: 99999999;
    border-radius: 4px;
    border: 1px solid #afafa9;
    box-shadow: 0 5px 10px #10182060;
    overflow: hidden;
    transition: height 3s ease-in-out;
}