/*
  Common variables:
  - font-size
  - color
  - padding
  - border-color
*/
.grid-component thead th {
  padding: 8px 5px;
  color: #2B2B2B; }

.grid-component tbody td {
  font-size: 0.85rem !important;
  color: #2B2B2B !important;
  padding: 8px 5px !important;
  border-bottom: solid 1px #e4eaec !important;
  /*  & > * {
    font-size: $fontSize !important; // TODO apply font-size to all the children of the td
  }*/ }

.grid-component tbody tr:hover {
  background-color: #f2f4f773 !important; }

@media screen and (max-width: 720px) {
  .grid-component tbody td {
    font-size: 0.75rem !important; } }

@media screen and (min-width: 720px) and (max-width: 1024px) {
  .grid-component tbody td {
    font-size: 0.80rem !important; } }

@media screen and (min-width: 1025px) and (max-width: 1280px) {
  .grid-component tbody td {
    font-size: 0.9rem !important; } }

@media screen and (min-width: 1281px) and (max-width: 1600px) {
  .grid-component tbody td {
    font-size: 1rem !important; } }
