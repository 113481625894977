.confirm-download-button{
    padding: 0.3rem 1rem;
    background: #007BFF;
    color: white;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cancel-download-button {
    padding: 0.3rem 1rem;
    background: #DD3333;
    color: white;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.confirm-download-button:disabled,.cancel-download-button:disabled{
    color:rgba(0, 0, 0, 0.26);
    border: 1px solid rgba(0, 0, 0, 0.12) !important;
    background-color: transparent;
    pointer-events: none;
    cursor: not-allowed;
}

.loader {
    width: 14px;
    height: 14px;
    border: 2px solid rgba(0, 0, 0, 0.26);
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}